import React from 'react'
import { navigate } from 'gatsby'

import { MainLayout } from '../layouts/MainLayout'
import { Header } from '../components/Header/Header'
import { isLogged } from '../services/auth'
import { isBrowser } from '../utils/utils'

export default ({ location }) => {
  const onLoginPage = location.pathname === '/login'
  if (!isLogged() && !onLoginPage) {
    isBrowser() && navigate('/login') // hack - this means gatsby-plugin-create-client-paths is not working
    return null
  }

  return (
    <MainLayout pathname={location.pathname}>
      <Header pathname={location.pathname} />
      Workout calendar
    </MainLayout>
  )
}